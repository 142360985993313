
























































































import { Research } from '@app/models';
import fb from 'firebase/app';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Datetime } from 'vue-datetime';

import { DateTime } from 'luxon';

@Component({
  components: { Datetime },
})
export default class ResearchDetailsA extends Vue {
  @Prop()
  public value!: Research.Mutable;

  public sectors = [
    /* TO DO: Move to Contentful */ 'catering',
    'healthcare',
    'automotive',
    'food',
    'non-food',
    'services',
    'others',
  ];

  public get startdate(): string {
    return this.ttod(this.value.date.start);
  }

  public async setStartdate(date: string, el: HTMLBceInputElement) {
    const timestamp = this.dtot(date);
    if (this.value.status === 'ready' && this.isToday(timestamp)) {
      const confirm = await this.$bce.confirm(
        this.$t('warning'),
        this.$t('warning-start-today'),
        { cancel: this.$t('false'), ok: this.$t('true') }
      );

      if (!confirm) {
        // el.value = this.startdate;
        return;
      }

      this.value.date.start = timestamp;
      this.value.status = 'active';
      this.$router.push(`/c/${this.$company.id}/research`);
    }

    this.value.date.start = timestamp;
  }

  public get enddate(): string {
    return this.ttod(this.value.date.end);
  }

  public set enddate(date: string) {
    this.value.date.end = this.dtot(date);
  }

  public get reminder1() {
    return this.value.reminder[0];
  }

  public set reminder1(value: string) {
    Vue.set(this.value.reminder, 0, value);
  }

  public get reminder2() {
    return this.value.reminder[1];
  }

  public set reminder2(value: string) {
    Vue.set(this.value.reminder, 1, value);
  }

  public get minStart() {
    return this.today;
  }

  public get minEnd() {
    return this.value.date.start
      ? this.value.date.start.toDate().toISOString()
      : this.in2Days;
  }

  public get minReminder2() {
    const reminder1 = this.value.reminder[0];
    if (!reminder1) return this.minEnd;

    const date = new Date(reminder1);
    date.setDate(date.getDate() + 1);
    return date.toISOString();
  }

  public get maxStart() {
    return this.value.date.end
      ? this.value.date.end.toDate().toISOString()
      : undefined;
  }

  private get today() {
    return new Date().toISOString();
  }

  private get in2Days() {
    const now = new Date();
    const in2Days = new Date();
    in2Days.setDate(now.getDate() + 2);

    return in2Days.toISOString();
  }

  private ttod(timestamp: any) {
    return timestamp ? timestamp.toDate().toISOString() : '';
  }

  private dtot(date: string) {
    return date ? fb.firestore.Timestamp.fromDate(new Date(date)) : null;
  }

  public isToday(timestamp?: FirebaseFirestore.Timestamp | null) {
    return (
      !!timestamp &&
      timestamp.toDate().toDateString() === new Date().toDateString()
    );
  }
}
